import * as React from "react"
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {graphql, useStaticQuery, Link} from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const BlogPage = () => {
    const {blog} = useStaticQuery(graphql`
        query BlogPageQuery {
            blog: allMdx(sort: {fields: frontmatter___date, order: DESC}) {
                posts: nodes {
                    slug
                    hero: heroImage {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                    id
                    meta: frontmatter {
                        title
                    }
                    excerpt
                }
            }
        }
    `)
    return (
        <Layout title={"Blog | Gatsby Ninja"}
                canonical={"https://gatsby-ninja.com/blog"}
                breadcrumbs={[{url: "/", name: "Home"}, {url: "/blog", name: "Blog"}]}
                breadcrumbsJustMeta={true}>
            <Seo title="Blog"
                 description="GatsbyNinja's Blog"
                 canonical={`https://gatsbyninja.com/blog`}
                 image={`https://gatsbyninja.com/hero-image.png`}
            />
            <section className={"px-6 py-8 max-container"}>
                <h1 className={"text-var-8xl block font-black"}>Blog</h1>
            </section>
            <section className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 max-container gap-4'}>
            {blog?.posts?.map(post => (
                <article key={post.slug} className={"w-full"}>
                    <Link to={"/blog/" + post.slug} className={"group my-5 flex w-full flex-col space-y-2 p-4 shadow-md duration-200  ease-in-out hover:shadow-lg md:hover:-translate-y-1"}>
                        <div className={"w-3/4 mx-auto md:w-full aspect-square h-full overflow-hidden"}>
                            <GatsbyImage className={"duration-200 w-full aspect-square h-full group-hover:scale-[102%]"} alt={post.meta.title}
                                         image={getImage(post.hero)}/>
                        </div>
                        <h2 className={"text-center md:text-left text-var-3xl py-4 font-black text-left "}>{post.meta.title}</h2>
                        <p className={"text-center md:text-left text-lg"}>{post.excerpt}</p>
                    </Link>
                </article>
            ))}
            </section>
        </Layout>)
}

export default BlogPage
